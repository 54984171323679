body > #vue-app {
  background-color: $cfourWhite;
}

.btn.btn-right, .btn > .fa {
  float: none;
}

.section-header {
  background: transparent;
  padding: 5px 0;
  border-bottom: 1px solid $cfourLightGray;
  margin-bottom: 30px !important;

    h1, h2, h3, h4, h5, h6 {
        color: #9A9898;
        font-size: 16px;
    }

    h4 {
        color: $cfourGrayFont;
        font-weight: 400;
        font-size: 16px;
    }

    a.section-link-all {
        color: $cfourGrayFont;
        right: 0;

        &:hover {
            color: $cfourGrayFont;
        }
    }
}

.cmp-hero {
    display: unset;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
    -ms-flex-flow: unset;
    flex-flow: row nowrap;
    .hero-main {
        width: 100%;
        display: block;
        flex: unset;
        -webkit-box-flex: unset;

        &+ .container-max {
            padding-left: 0;
            padding-right: 0;
        }
        .carousel {
            .carousel-indicators {
                li {
                    border-color: $cfourPrimaryColor;
                    &.active {
                        background: $cfourPrimaryColor;
                    }
                }
            }
            .carousel-control {
                span:before {
                    color: $cfourPrimaryColor;
                }
            }
            .carousel-inner {
                .carousel-item {
                    .hero-category:hover {
                        h1, h2, h3 {
                            background: rgba(62, 56, 44, 0.9);

                            &:hover {
                                background: rgba(62, 56, 44, 1);
                            }
                        }
                    }
                    .carousel-caption {
                        h1, h2, h3 {
                            background: rgba(62, 56, 44, 0.9);

                            &:hover {
                                background: rgba(62, 56, 44, 1);
                            }
                        }
                    }
                }
            }
        }
    }
    .hero-xtras {
        width: 100%;
        display: block;
        flex: unset;
        -webkit-box-flex: unset;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        flex-flow: unset;
        padding: 0;

        .hero-category {
            .hero-category-inner {
                .hero-category-headline {
                    background: rgba(62, 56, 44, 0.9);

                    &:hover {
                        background: rgba(62, 56, 44, 1);
                    }
                }
            }
            &> img {
                position: relative;
                top: unset;
                right: unset;
                bottom: unset;
                left: unset;
                margin: unset;
                min-width: unset;
                width: 100%;
            }
        }
    }
}

.sale-new {
    .featured-sale-new > img {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
}

.c4brands {
    .c4brandsheadline {
        margin-bottom: 20px;
        h2 {
            text-transform: uppercase;
            text-align: center;
            font-size: 40px;
            font-weight: 100;
        }
        hr {
            width: 175px;
            border-color: $cfourPrimaryColor;
        }
    }
    .c4brandsimages.c4grayscalebrands img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }
    .c4brandsimages {
        .c4brandlogo {
            position: relative;
            height: 150px;
            margin-bottom: 35px;
            margin-top: 35px;
            img {
                max-height: 100%;
                max-width: 100%;
                width: auto;
                height: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
    .c4textafterbrands {
        margin-top: 20px;
    }
}

.c4brandlogo img {
    width: 100%;
}


@include media-breakpoint-down(xs) {
    .cmp-hero .hero-xtras .hero-category > img {
        width: 100%;
    }
    .cmp-hero .hero-xtras .hero-category {
        max-height: 50vh;
    }
}
