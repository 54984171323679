.footer {
    font-size: 14px;
    font-weight: 300;
    background: $cfourWhite;
    .c4footerentries {
        text-transform: uppercase;

        & > .m-y-2 {
            border-color: $cfourPrimaryColor;
        }
    }
    .c4footerheadline {
        border-bottom: 1px solid $cfourPrimaryColor;
        padding-bottom: 6px;
        font-size: 16px;
        display: block;
    }
    a {
        font-weight: bold;
        color: $cfourPrimaryColor;
    }
    .c4footerbox {
        padding: 15px 0;

        li {
            list-style: none;
        }
        li.c4footershoppinglink {
            padding-bottom: 10px;
        }
        .c4cloud {
            display: inline-block;
            margin: 2px 0;
            padding: 1px 5px;
            border: 1px solid $cfourLightGray;
        }
    }
    .c4footercontact {
      i {
          font-size: 20px;
          margin-right: 5px;
      }
        a {
            font-weight: 300;
        }
    }
    .c4footeremail {
      text-transform: lowercase;
    }
    .c4footersocialmedia {
        i {
            font-size: 20px;
            border: 1px solid #efefef;
            padding: 5px 10px;
            margin-right: 2px;
        }
        span{
            &:last-child{
                text-transform: lowercase;
            }
        }
    }
    .back-to-top-center {
        background: $cfourSecondaryColor;
        bottom: -5px;
        width: 50px;
        right: 50px;
        border: none;
        opacity: 1;
        border-radius: 0;
        left: auto;
        i {
            width: 50px;
            color: #FFFFFF;
        }
    }
    .c4footerabout {
        text-transform: none;
    }
    .c4footerAboutUs {
        padding-top: 20px;
        .cfourfootersvg{
            position: relative;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            max-width: 200px;
            margin-bottom: 10px;
        }
    }
    .c4footerShopping {
        padding-top: 20px;
        .c4footershopping {
            a {
                padding-bottom: 10px;
                display: inline-block;
            }
        }
    }
    .c4footerInformation {
        padding-top: 20px;
        a {
            display: inline-block;
            padding-bottom: 10px;
        }
    }
    .c4footersymbol{
        padding-right: 18px;
    }
}
#c4copyright{
    background-color: #dedfcf;
    height: auto;
    padding: 22px;
    .container-max.c4footercopyright a {
        color: $cfourPrimaryColor;
        font-weight: bold;
    }
    .c4footerPayments{
        i{
            font-size: 22px;
            padding-right: 30px;
        }
    }
}
