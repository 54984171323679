
.wrapper-main header .navbar .container-max > .row {
  flex-direction: row;
}
html nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li > ul.collapse > li ul.nav-dropdown-inner {
  background: transparent;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}
.list-controls .btn-link {
  color: #fff !important;
}
.no-touch nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li:hover, .touch nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li.hover {
  background: #fff !important;
  color: #004157 !important;
}

nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li > ul.collapse > li ul.nav-dropdown-inner .level1 > a {
  color: #3e382c;
  font-weight: bold;
}
nav.navbar.megamenu #mainNavbarCollapsable ul.mainmenu > li > ul.collapse > li a {
  border-top: 3px solid #fff;
  border-bottom: 2px solid #fff;
  display: block;
}
#mainNavbarCollapsable ul.mainmenu li > ul > li:hover > a {
  border-color: #3e382c !important;
  /* color: #062e47; */
}
html .add-item .fa {
  color: inherit;
  border-color: inherit;
}
.cmp-address-list .item-content.small {
  color: inherit;
}

.cmp-address-list .dropdown-menu.show {
  transform: translate3d(0px, 114px, 0px) !important;
}

#page-header-parent > .container-max,
#page-header-parent > .navbar,
#page-header-parent > .unfixed {
  top: 0 !important;
  position: inherit !important;
}

#page-header-parent > .container-max.cfour-cachet {
  top: 0 !important;
  position: relative !important;
}
