#mainNavbarCollapsable {
  background: $cfourLightGray;
  -webkit-box-shadow: inset 0 5px 10px 1px rgba(0, 0, 0, .3);
  box-shadow: inset 0px 10px 40px -15px rgba(0, 0, 0, .3);

  ul.mainmenu {
    color: $cfourPrimaryColor;
    text-align: center;
    //QUICKFIX temporär
    float: none !important;

    li {
      float: none;
      text-transform: uppercase;

      a {
        padding: 1rem 1rem;
      }

      &:hover {
        background-color: transparent;
        color: $cfourPrimaryColor;
      }

      & > ul {
        background: #FFFFFF;

        & > li {
          color: $cfourPrimaryColor;

          &:hover {
            //background: $cfourSecondaryColor;
            color: $cfourPrimaryColor;
          }
        }
      }
    }
  }
}

nav.navbar.megamenu {
  @include media-breakpoint-up(lg) {
    position: relative;
  }

  #mainNavbarCollapsable {
    background-color: #DBD7CC;

    @include media-breakpoint-up(lg) {
      ul.mainmenu {
        justify-content: center;

        > li {
          a {
            padding: 1rem 1rem;
          }

          &:hover {
            color: $cfourPrimaryColor !important;
          }

          > ul.collapse {
            min-height: unset;

            > li {

              &.level1 {
                > a {
                  color: $cfourPrimaryColor;
                }
              }

              a {
                color: $cfourPrimaryColor;

                &:hover {
                  color: $cfourPrimaryColor !important;
                }
              }
            }

          }

        }
      }
    }
  }
}

.mobile-navigation {
  color: $cfourPrimaryColor;
  background: $cfourWhite;

  ul.breadcrumb {
    background: $cfourSecondaryColor;
    color: $cfourWhite;
  }
}
