body {
  background-color: $cfourWhite;
  color: $cfourPrimaryColor;


  a {
    outline: none;
    color: $cfourTertiaryColor;

    &:hover {
      color: $cfourPrimaryColor;
    }
  }

  a.text-primary {
    outline: none;
    color: $cfourTertiaryColor !important;

    &:hover {
      color: $cfourPrimaryColor !important;
    }
  }

  .form-control:focus {
    border-color: $cfourTertiaryColor;
  }
}
